<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>系统用户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">

            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="工号">
                                <el-input v-model="search.job_number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="用户名称">
                                <el-input v-model="search.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号">
                                <el-input v-model="search.mobile"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="this.Tool.is_auth('systemset.user.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="tosee()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium">
                <el-table-column prop="job_number" label="工号" width="100"></el-table-column>
                <el-table-column prop="name" label="姓名" width="140"></el-table-column>
                <el-table-column prop="state" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="warning">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="mobile" label="手机号" width="140"></el-table-column>
                <el-table-column prop="role_name" label="角色"></el-table-column>
                <el-table-column prop="position" label="职务" width="140"></el-table-column>
                <el-table-column prop="create_time" width="150" label="添加时间"></el-table-column>
                <el-table-column prop="update_time" width="150" label="修改时间"></el-table-column>
                <el-table-column prop="operation" label="操作" fixed="right" width="400">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('systemset.user.getinfo')"
                                   @click="tosee(scope.row.user_uuid)"
                                   size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('systemset.user.resetpassword')"
                                   @click="operation_tip(scope.row.user_uuid,scope.row.name,'resetpassword')"
                                   size="mini">初始化密码
                        </el-button>
                        <el-button
                                v-if="is_auth('systemset.user.isenable') && scope.row.state===2 && scope.row.is_sysadmin!==1"
                                @click="operation_tip(scope.row.user_uuid,scope.row.name,'isenable')"
                                type="primary" size="mini">启用
                        </el-button>
                        <el-button
                                v-if="is_auth('systemset.user.isdisable') && scope.row.state===1 && scope.row.is_sysadmin!==1"
                                @click="operation_tip(scope.row.user_uuid,scope.row.name,'isdisable')"
                                type="warning" size="mini">禁用
                        </el-button>
                        <el-button
                                v-if="is_auth('systemset.user.isdel') && scope.row.is_sysadmin!==1"
                                @click="operation_tip (scope.row.user_uuid,scope.row.name,'isdel')"
                                type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '系统用户',
            loading: true,      // 加载状态
            issearch: false,    // 搜索
            search: {},         // 搜索条件
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            // 设置搜索条件
            this.search = {
                name: '',
                job_number: '',
                mobile: '',
            }
            this.is_search()
        },
        // 搜索
        is_search() {
            this.page = 1
            this.getlist();// 获取数据
        },
        // 获取列表
        getlist() {
            // 必须参数
            let postdata = {
                api_name: "systemset.user.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        // 查看
        tosee(user_uuid = '') {
            if (user_uuid === '') {
                this.$router.push({path: '/systemset/user/see'})
            } else {
                this.$router.push({path: '/systemset/user/see', query: {user_uuid: user_uuid}})
            }
        },
        // 操作提示
        operation_tip(user_uuid = '', name = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + name + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + name + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + name + '】？'
                options = {type: 'error'}
            }
            // 重置密码
            if (operation === 'resetpassword') {
                tip = '重置【' + name + '】的密码？'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(user_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(user_uuid = '', operation = '') {
            let postdata = {
                api_name: "systemset.user." + operation,
                token: this.Tool.get_l_cache('token'),
                user_uuid: user_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
